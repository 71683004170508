.timer {
    width: 100%;
    height: 100;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.timer>h3 {
    font-size: 4rem;
}

.timer>h1 {
    color: rgb(var(--primary-color));
    font-size: 10rem;
    font-family: "JetBrains Mono";
    font-weight: bold;
}