.alert {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background-color: var(--background-color-transparent);
}

.alert--content {
  --alert-content-padding: 2rem;
  --alert-shadow-blur: 10px;
  --alert-shadow-spread: -2px;

  background-color: rgb(var(--background-color));
  max-width: 600px;
  width: 90%;
  box-sizing: border-box;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: var(--alert-content-padding);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  text-align: center;
}

.alert--secondary-text {
  color: var(--accent-color);
  font-size: 0.9rem;
}

.alert--buttons {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
