.events__header {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 3.5rem;
}

.events h3 {
  /* color: var(--text-one-color); */
  width: fit-content;
  margin: 2rem 0 0.5rem 0;
}

.events__noevents {
  width: 100%;
  text-align: center;
}

.events img {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-20%);
  width: 100%;
  z-index: -1;
}
