.tad {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.tad h2 {
  margin-bottom: 1rem;
}

.tad p {
  color: var(--accent-color);
}

.tad b {
  color: var(--text-one-color);
}
