.account {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.account__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: rgb(var(--primary-color));
  padding-left: 1.5rem;
  border: 2px solid rgb(var(--primary-color));
}

.account__header--active {
  background-color: rgb(var(--background-color));
}

.account__header > input {
  color: rgb(var(--background-color));
  background-color: transparent;
  font-size: 2.6rem;
  font-weight: bold;
  max-width: unset;
  min-width: unset;
  padding: 0;
  font-family: "Russo One";
  overflow: visible;
}

.account__header--active > input {
  color: rgb(var(--primary-color));
}

.account__header > div {
  cursor: pointer;
  padding: 0 0.9rem;
}

.account__header > div > img {
  height: 1.7rem;
}

.account__header--active > div > img {
  filter: brightness(0) saturate(100%) invert(83%) sepia(42%) saturate(1522%)
    hue-rotate(356deg) brightness(98%) contrast(107%);
}

.account__verification-info {
  border: 2px solid rgb(var(--primary-color));
  box-sizing: border-box;
  padding: 0.6rem;
}

.account__verification-info > span {
  color: var(--text-one-color);
  font-size: 0.8rem;
}

.account__task {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  box-sizing: border-box;
  border: 2px solid rgb(var(--primary-color));
  padding: 1rem;
}

.account__task > h5 {
  color: rgb(var(--primary-color));
}

.account__task > span {
  font-size: 0.9rem;
  color: var(--text-one-color);
}

.account__task > span > b {
  color: rgb(var(--primary-color));
}

.account__table {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
}

.account__table > li {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.5rem;
  box-sizing: border-box;
}

.account__table > li:nth-child(odd) {
  background-color: var(--accent-color);
}

.account__table > li:nth-child(even) {
  background-color: var(--secondary-color);
}

.account__table > li > span {
  font-weight: bold;
  display: flex;
  align-items: center;
}

.account__table > li > img {
  width: 1.5rem;
  height: 1.5rem;
}

.account__settings {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  gap: 1rem;
  flex-wrap: wrap;
}

@media screen and (max-width: 490px) {
  .account__settings > div {
    width: 100%;
  }
}
