.documents {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.documents__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  text-align: center;
  margin-bottom: 1rem;
}

.documents__api-wrappers {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.documents__api-wrappers > ul {
  box-sizing: border-box;
  padding-left: 1.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
  margin: 0.5rem 0;
}

.documents__api-wrappers > ul > li {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
