.reset {
  --input-padding: 0.7rem;
  --input-border-width: 3px;

  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.reset > form {
  max-width: 600px;
}

.reset--submit {
  width: 100% !important;
  cursor: pointer;
  margin-top: 1rem;
}

.reset--submit:hover {
  background-color: rgb(var(--background-color));
  color: rgb(var(--primary-color));
}
