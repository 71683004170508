.event {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.event--format {
  display: flex;
  align-items: center;
  justify-content: center;
}

.event--format img {
  width: 100%;
  max-width: 800px;
  margin-top: 2rem;
}

.event--welcome {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding-bottom: 5rem;
}

.event--welcome span {
  color: var(--accent-color);
}

.event--welcome h1 {
  color: rgb(var(--primary-color));
}

.event--welcome h6 {
  max-width: 800px;
}

.event--section span {
  color: var(--accent-color);
}

.event--section {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 800px;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  padding: 1rem;
  padding-bottom: 0;
  padding-top: 5rem;
}

.event--section__photos {
  max-width: 1000px;
}

.event--section li {
  color: var(--text-one-color);
  text-align: left;
}

.event--clock {
  width: 100%;
  background-color: rgb(var(--primary-color));
  padding: 2rem 0;
}

.event--clock h4 {
  color: rgb(var(--background-color));
  width: 100%;
  text-align: center;
}

.event--faq {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5rem 0;
}

.prizes {
  max-width: 1100px;
}

.prizes__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
  gap: 0.5rem;
}

.prizes__podium {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0;
  padding: 0;
  gap: 1.5rem;
}

.prizes__standing {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  flex: 1;
}

@media screen and (max-width: 900px) {
  .prizes__podium {
    flex-direction: column;
  }

  .prizes__podium > li {
    margin: 0;
  }

  .prizes__standing--first {
    order: 1;
  }

  .prizes__standing--second {
    order: 2;
  }

  .prizes__standing--third {
    order: 3;
  }
}

.prizes__standing * {
  text-align: center;
}

.prizes__standing > ul {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  margin: 0;
  gap: 0.3rem;
}

.prizes__standing > ul > li {
  text-align: center;
  text-decoration: none;
  width: 100%;
  box-sizing: border-box;
  list-style-type: none;
  font-size: 0.8rem;
  color: var(--accent-color);
}

.prizes__standing--second {
  margin-top: 1rem;
}

.prizes__standing--third {
  margin-top: 1rem;
}
