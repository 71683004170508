.navbar {
  height: 100%;
  display: flex;
  align-items: center;
}

.navbar__sidebar {
  --navbar-sidebar-padding: 1rem;

  background-color: rgb(var(--background-color));
  position: absolute;
  right: 0;
  top: 100%;
  width: calc(100% - var(--navbar-sidebar-padding) * 2);
  height: 100vh;
  padding: var(--navbar-sidebar-padding);
  transform: translateX(0);
  transition: all 0.25s ease-in-out;
  z-index: 1000;
}

@media screen and (max-width: 768px) {
  .navbar__sidebar a {
    font-size: 1.5rem;
  }
}

.navbar__sidebar--hidden {
  transform: translateX(100%);
}

.navbar__sidebar-icon {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%)
    contrast(103%);
  cursor: pointer;
  height: 100%;
  outline: none;
  border: none;
}

.navbar__sidebar-icon > img {
  height: 100%;
  transition: all 0.25s ease-in-out;
}

.navbar__nav {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .navbar__nav {
    flex-direction: column;
    padding: 1rem 0;
    gap: 2rem;
  }
}

@media screen and (min-width: 768px) {
  /* .navbar__link:hover {
    color: rgb(var(--background-color));
    text-shadow: -1px -1px 0 white, 1px 1px 0 white, -1px 1px 0 white,
      1px -1px 0 white, -1px 0 0 white, 1px 0 0 white, 0 1px 0 white,
      0 -1px 0 white, 2px -2px 0 white, 1px -2px 0 white, 2px -1px 0 white;
  }

  .navbar__link--special:hover {
    color: rgb(var(--background-color));
    text-shadow: -1px -1px 0 rgb(var(--primary-color)),
      1px 1px 0 rgb(var(--primary-color)), -1px 1px 0 rgb(var(--primary-color)),
      1px -1px 0 rgb(var(--primary-color)), -1px 0 0 rgb(var(--primary-color)),
      1px 0 0 rgb(var(--primary-color)), 0 1px 0 rgb(var(--primary-color)),
      0 -1px 0 rgb(var(--primary-color)), 2px -2px 0 rgb(var(--primary-color)),
      1px -2px 0 rgb(var(--primary-color)), 2px -1px 0 rgb(var(--primary-color));
  } */
}

.navbar__link {
  color: var(--text-one-color);
  font-size: 1rem;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.15s;
  position: relative;
  padding: 0rem 0.7rem;
}

@media screen and (max-width: 768px) {
  .navbar__link {
    font-size: 1.5rem;
  }
}

.navbar__link--special {
  color: rgb(var(--primary-color));
}

.navbarButton {
  position: relative;
}

.navbarButton__double {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--text-one-color);
  width: 0;
  overflow: hidden;
  transition: all var(--hover-transition-time) ease-out;
}

.navbarButton__double--special {
  background-color: rgb(var(--primary-color));
}

.navbarButton__double > a {
  color: rgb(var(--background-color));
}

@media screen and (min-width: 768px) {
  .navbarButton:hover .navbarButton__double {
    width: 100%;
  }
}
