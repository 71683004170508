.sponsors {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sponsors__section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3rem;
}

.sponsors__section:last-of-type {
  margin-bottom: 0;
}

.sponsors__section h2 {
  margin-bottom: 2rem;
  font-size: 1.9rem;
}

.sponsors__section div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 2rem;
}

.sponsors__section div a {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sponsors__section div a img {
  height: 40px;
}
