.memberRegister {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.form__checkbox--wrapper {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 1rem 0;
  font-size: 0.9rem;
}
